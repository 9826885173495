<template>
  <!-- 隐私政策 -->
  <div class="privacy-policy">
    <OldHeader
      class="head-wrap"
      scene="userHome"
      :isWhiteBg="true"
      :isShowLogin="false"
    ></OldHeader>
    <h1 class="p-title">微吼隐私政策</h1>
    <p class="p-timer">更新日期：【2025】年【03】月【10】日</p>
    <p class="p-timer margin-24">生效日期：【2025】年【03】月【10】日</p>
    <p class="level-2">
      北京微吼时代科技有限公司及其关联方（以下简称“微吼”）是企业级互动视频直播云平台和一站式互动视频解决方案服务商，致力于通过视频互动技术，为客户提供数字学习技术和数字营销云解决方案，助力企业实现数字化转型。微吼合法拥有并运营微吼官方网站、APP、客户端应用程序等微吼平台。
    </p>
    <p class="level-2">
      微吼深知个人信息及隐私对您及其重要并非常重视和保护您的个人信息及隐私。为此，微吼依据《个人信息保护法》、《数据安全法》、《网络安全法》等有关法律法规，特制定《微吼隐私政策》（以下简称“本政策”）。本政策是您与微吼建立的，就您下载、安装、试用、注册、登录微吼官方网站，和/或者APP，和/或者客户应用程序等并试用、或者使用全部微吼产品和服务具有法律约束力的协议。
    </p>
    <p class="level-2 font-bold font-italic">
      请您在使用微吼产品和服务之前务必认真阅读本政策，特别是免除或者减轻微吼责任、排除或者限制您的权利、法律适用及争议解决等条款，并审慎判断风险，自主决定是否接受本政策。如果您不同意本政策，请您不要下载、安装、试用、注册、登录、使用微吼的产品和服务。如您进行前述操作视为您已经阅读并同意本政策，并受本政策的约束。
    </p>
    <p class="level-2">本政策将帮助您了解以下内容：</p>
    <p class="level-2">一、微吼如何收集和使用您的个人信息</p>
    <p class="level-2">二、微吼如何共享、转让和公开披露您的个人信息</p>
    <p class="level-2">三、微吼如何存储您的个人信息</p>
    <p class="level-2">四、微吼如何保护您的个人信息</p>
    <p class="level-2">五、微吼如何使用Cookie 和同类技术</p>
    <p class="level-2">六、微吼如何处理未成年人的个人信息</p>
    <p class="level-2">七、您的权利及微吼处理个人信息的基本原则</p>
    <p class="level-2">八、本政策如何查阅和本政策的修订</p>
    <p class="level-2">九、通知送达及推送</p>
    <p class="level-2">十、法律适用和争议解决、侵权投诉和举报渠道</p>
    <div class="one">
      <p class="level-1 font-bold">一、微吼如何收集和使用您的个人信息</p>
      <p class="level-2">
        为了更好的提供产品和服务、保障平台正常运行、改进和优化产品和服务质量，微吼会出于本政策所述的以下目的，收集和使用您在使用微吼产品和服务过程中主动提供或由于产品和服务需要而产生的您的如下个人信息：
      </p>
      <section>
        <p class="level-2 font-bold">（一）试用微吼产品和服务、获取资料</p>
        <p class="level-2">
          当您想要试用微吼的产品和服务、获取发布的白皮书、指南等资料时，微吼会要求您填写姓名、企业名称、职位、手机号码等基本信息。为了更好的为您提供服务，并给予您相应的试用指导或资料分享，您同意并认可微吼客服有权通过您预留的联系方式给您打电话、发短信、添加微信好友等方式与您联系。您进一步同意，微吼客服除了向您进行微吼产品服务介绍、使用功能指导之外，还有权向您介绍微吼产品和服务的商务价格、商务策略等。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（二）注册和登录微吼账号</p>
        <p class="level-2">
          当您在微吼注册账号时，微吼会要求您提供手机号码或者电子邮箱。手机号码、电子邮箱等用于您注册、登录、绑定账号、密码找回时接受验证码，并且作为您与微吼指定的联系方式之一。您同意，微吼有权给您打电话、发短信、发送电子邮件等，对您使用微吼的产品和服务进行回访，服务对账、或者向您介绍微吼最新产品功能，或者向您推荐产品的商务策略等。
        </p>
        <p class="level-2 font-bold font-italic">
          收集手机号码是也是履行国家关于网络实名制要求的必要信息，如果您拒绝提供手机号码用于注册、登录，将无法完成身份认证，导致直播功能受限，微吼可能无法为您提供更完整的产品和服务。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（三）授权登录</p>
        <p class="level-2">
          如果您选择通过第三方授权方式注册或者登录微吼，如：您可以通过微信扫二维码的方式或者QQ账号等方式登录微吼。微吼可能会根据您的授权从微信、QQ等程序处获取您的账号信息（包括微信昵称和头像；QQ昵称、头像、性别），并与您的微吼账号进行绑定，使您可通过微信或者QQ账号直接登录、使用微吼的产品和服务或者第三方依托微吼提供的产品及/或者服务。微吼将在您授权同意的范围内使用您的个人信息。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（四）直播功能开通信息收集</p>
        <p class="level-2">
          如果您希望开通直播功能，根据国家有关规定，微吼可能需要您完成身份认证。针对个人可能需要您提供联系人姓名、手机号码等有关资料和信息；若为企业则可能需要您提供联系人姓名、手机号码、电子邮箱、QQ号码、办公电话、微信号、公司名称、网址、行业、业务范围等有关资料和信息。收集这些信息是为了满足国家有关实名认证的要求。若您不提供这类信息，将无法完成身份认证，导致直播受限，可能无法正常使用微吼的相关产品和服务。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（五）直播活动相关的权限授权</p>
        <p class="level-2">
          当您拍摄或者发布音视频、图片，或者发布直播时，微吼会根据您使用的功能类型分别请求您授权相机、相册、麦克风权限。
          如您拒绝授权，将无法使用拍摄、发布或者直播功能，但不影响您使用微吼的其他基础功能。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（六）微吼账号后台管理</p>
        <p class="level-2">
          当您获得微吼账号后，可以通过后台对您的账号进行管理。如提交主页头像、设置昵称、留下联系姓名、电话、手机号码、绑定微信号和QQ号、填写所在行业等信息。如果您不预留前述信息，不影响您继续使用微吼产品和服务的基本功能。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（七）直播视频、音频、图片、文字等管理</p>
        <p class="level-2">
          当您使用微吼直播服务时，您上传的视频、音频、图片、文字等信息会存储在微吼服务器中，因为存储是实现这一功能所必需的。您可以随时删除这些信息，除非经您自主选择或者遵从相关法律法规要求。除《微吼用户服务协议》或者本政策另有明确约定，微吼不会对外提供或者将其用于其他用途。请注意，您公开发布的信息中可能会涉及您或者他人的个人信息甚至个人敏感信息，因此请您谨慎地考虑使用该功能。如您坚持上传并公开发布前述信息，相关的法律后果和责任将有您承担。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（八）直播观看限制</p>
        <p class="level-2">
          温馨提示您，有些直播内容提供者提供的直播服务设置了观众限制。如您想要观看相关场次的直播，需要您填写
          <span class="font-italic">报名表单</span>
          ，表单中需要您提供姓名、性别、手机、邮箱、地域、公司、职务等信息。收集您的前述信息是为了对您的条件进行审核，只有通过审核后您才有资格观看该场次的直播活动。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（九）抽奖活动</p>
        <p class="level-2">
          有些直播活动的直播内容提供者设置了抽奖环节。如您想要参加抽奖活动，您需要提供填写与领奖相关的信息，如您的姓名、手机号码、地址等。填写此类信息是保障您如果中奖后相关奖品的交付等。如果您不想参加抽奖活动，您可以不填写与领奖相关的信息，且不会影响您观看该场次直播。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（十）问卷调查</p>
        <p class="level-2">
          有些直播活动中，设置了问卷调查环节。问卷调查是让您对直播活动、相关产品和服务等进行的调研。问卷调研中需要您填写姓名、性别、手机、邮箱、地域、公司、职务、生日、行业等信息。如果您不想参加调研活动，您可以不填写调查问卷，且不会影响您观看该场次直播活动。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（十一）设备、网络相关信息</p>
        <p class="level-2">
          为保障您正常使用微吼的服务，微吼会收集您的设备名称（包括别名）、设备型号、操作系统、WIFI信息、网络信息、显卡型号、CPU型号、CPU占用率、设备电量、设备制造商、网络状态、摄像头及麦克风设备信息、IP地址、基站信息、MAC地址、设备软件版本信息、设备识别码、设备标识码、设备标识符、信息所在地区、网络使用习惯、操作记录以及其他与微吼服务相关的日志信息，这类信息是为提供服务必须收集的基础信息，如您不同意微吼记录前述信息，可能无法完成风控验证。
        </p>
        <p class="level-2">
          请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果微吼将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或者法律法规另有规定外，微吼会将该类个人信息做匿名化、去标识化处理。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（十二）沟通信息的记录</p>
        <p class="level-2">
          当您与微吼联系，或者微吼联系您时，微吼可能会保存您的通信/通话记录和内容或者您留下的联系方式信息，以便与您联系或者帮助您解决问题，或者记录相关问题的处理方案及结果。除此以外，还可能存在如下信息的收集或者得到您的授权：
        </p>
        <p class="level-2">申请开启电话权限，获取电话状态，以方便一键拨打客服电话；</p>
        <p class="level-2">申请开启相机权限，以拍摄文件照片、印章照片等；</p>
        <p class="level-2">申请开启通讯录权限，获取联系人信息，以方便邀请他人参加直播活动；</p>
        <p class="level-2">
          申请开启位置权限，获取定位信息，以便于获取更准确的数据，提供基础反作弊能力。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（十三）使用微吼产品过程中的行为信息</p>
        <p class="level-2">
          当您使用微吼产品和服务时，微吼可能会记录您的点击、浏览、分享、关注、点赞、评论等的记录，以及在直播间观看直播时的互动、聊天等。该类信息的保存类似于日志记录，仅仅是行为的记录，目前微吼不会用于数据分析等。
        </p>
        <p class="level-2">
          当您在直播间观看直播时，直播活动组织者或者第三方可能会发布小程序、小游戏、购物链接、广告图片等。直播活动组织者或者第三方可能会处理为实现功能、目的所需的信息。您应当谨慎决定是否使用相关功能并提供个人信息。除非另有说明，微吼不直接处理您的该等个人信息。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（十四）其他信息</p>
        <p class="level-2">
          依据不断变化的法律、法规和监管要求，以及微吼产品和服务的不断优化升级，微吼可能会更改收集信息的类别、范围，包括增加或者减少信息收集。届时微吼会调整本政策。相关个人信息处理有关的内容请您关注本政策的调整。
        </p>
        <p class="level-2">
          以上是微吼收集您信息的主要类别。为了方便您进一步了解，微吼将上述个人信息分为两大类：
        </p>
        <p class="level-2">
          （一）微吼产品和服务的核心业务功能
          <span class="font-bold font-italic">所必需的信息</span>
          。此类信息为产品和服务正常运行的必要信息，您须授权微吼收集和使用。如您拒绝，您将无法正常使用微吼的产品/服务。
        </p>
        <p class="level-2">
          （二）核心业务功能外的产品和服务
          <span class="font-bold font-italic">可能需要收集的信息</span>
          。您可以选择是否授权微吼收集。如您拒绝提供，将导致某些功能无法实现或无法达到预期的效果，但不影响您对核心业务功能的正常使用。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">二、微吼如何共享、转让和公开披露您的个人信息</p>
      <section>
        <p class="level-2 font-bold">（一）共享</p>
        <p class="level-2">
          微吼不会与微吼之外的公司、组织和个人共享您的个人信息，但以下情况除外：
        </p>
        <p class="level-2">
          1、在获取明确同意的情况下共享。获得您的明确同意后，微吼会与其他方共享您的个人信息。
        </p>
        <p class="level-2">
          2、在法定情形下的共享。微吼可能会根据法律法规规定，或者按行政、司法机关依法提出的强制性要求，对外共享您的个人信息。
        </p>
        <p class="level-2">
          3、与授权合作伙伴共享。仅为实现本政策中声明的目的，微吼的某些服务将由微吼和合作伙伴共同提供。微吼仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。微吼的合作伙伴无权将共享的个人信息用于与产品或者服务无关的其他用途。如：您使用微信支付（财付通支付科技有限公司）向微吼购买产品和服务等，微信支付可能会收集您的个人信息。相关个人信息的处理请您参考微信支付的隐私政策（
          <a href="https://www.tenpay.com/v3/helpcenter/low/privacy.shtml" target="_blank">
            https://www.tenpay.com/v3/helpcenter/low/privacy.shtml
          </a>
          ）。您使用支付宝（支付宝（中国）网络技术有限公司）向微吼购买产品和服务等，支付宝可能会收集您的个人信息。相关个人信息的处理请您参考支付宝的隐私政策：
          <a
            href="https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132"
            target="_blank"
          >
            https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132
          </a>
          。
        </p>
        <p class="level-2">
          4、通过SDK方式或者第三方APP与第三方共享。某些产品或者服务可能由第三方提供或者由微吼与第三方共同提供，因此只有共享您的信息，才能提供您需要的产品或者服务，这些产品和服务功能通过嵌入插件（如SDK、第三方APP等）的形式向您提供。在此过程中，第三方服务提供者可能会收集、使用和存储您的相关数据或者信息。
        </p>
        <p class="level-2 font-bold font-italic table-title">第三方SDK/APP收集个人信息详情如下：</p>
        <vh-table :data="staticData" :show-header="false" border :span-method="arraySpanMethod">
          <vh-table-column prop="label" width="180"></vh-table-column>
          <vh-table-column>
            <template slot-scope="scope">
              <div v-html="scope.row.content"></div>
            </template>
          </vh-table-column>
        </vh-table>
        <p class="level-2">
          微吼通过与上述第三方服务提供者签署的有关协议、制定规范以及合理的产品流程设计，严格要求第三方服务提供者获取您的任何数据均应遵守相关法律法规的规定，采取必要的数据保护措施（例如将该类个人信息做匿名化、去标识化处理），且仅为产品或者服务之目的合理使用您的相关数据，向您提供修改或者删除自己数据的方式，尽最大可能保障您的数据和隐私不受侵害。您在接受或者使用第三方产品或者服务前应充分了解第三方产品或者服务的条款及政策，如果您发现有第三方服务提供者存有相关违法违规行为，可以向微吼投诉，微吼将查实后予以处理。您如果需要撤回对第三方共享的授权可以通过关闭SDK功能或联系客服），撤回授权后将会导致直播功能受限，可能无法正常使用微吼的相关产品和服务。
        </p>
        <p class="level-2">
          5、对于某些直播内容提供者设置了观众限制的直播，如您填写了报名表单。该报名表单的信息微吼可能会共享给直播内容提供者，由其审核是否允许您观看该场次直播。您可以通过不填写报名表单不参与该场直播的方式来终止相关信息的提供。
        </p>
        <p class="level-2">
          直播活动在进行促销或者抽奖时，如果您参与促销或者抽奖活动填写的与领奖相关的信息，微吼可能会与直播内容提供者抽奖活动组织者、赞助商等共享您的个人信息，当您参与促销或者中奖后，当事人可以通过您预留的信息向您提供商品、服务或者奖品。您可以通过不填写相关信息并不参与活动来终止相关信息的提供。
        </p>
        <p class="level-2">
          直播过程中的问卷调查内容，微吼可能共享给直播内容提供者，由其对您进一步分析，以决定是否需要向您进一步介绍直播内容提供者的相关产品和服务。您可以通过不填写调查问卷的方式拒绝相关信息的提供。
        </p>
        <p class="level-2">
          基于上述情形共享的信息，微吼或者直播内容提供者可能会给你打电话、发短信、发送电子邮件等追踪您对直播活动的满意度、对直播涉及的产品或者服务的评价、以及向您推荐相关产品或者服务等。您如果对此感到不适可以明确拒绝，微吼或者直播内容提供者将中止相关行为。
        </p>
        <p class="level-2">
          为了给您提供更好的产品和服务，或者为了预防互联网犯罪，微吼的关联公司、合作伙伴等会依据有关法律法规或者征得您同意的前提下，向微吼分享您的个人信息。微吼可能从第三方获取您授权共享的账号信息（头像、昵称），并在您同意本政策后将您的第三方账号与您的账号绑定，使您可以通过第三方账号直接登录并使用微吼的产品或者服务。微吼将依据与第三方的约定，在符合相关法律法规规定的前提下，合理使用您的个人信息。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（二）转让</p>
        <p class="level-2">微吼不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p class="level-2">
          1、在获取明确同意的情况下转让。获得您的明确同意后，微吼会向其他方转让您的个人信息；
        </p>
        <p class="level-2">
          2、在微吼发生合并、收购或者破产清算情形，或者其他涉及合并、收购或者破产清算情形时，如涉及到个人信息转让，微吼会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则微吼会要求该公司、组织和个人重新向您征求授权同意。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（三）公开披露</p>
        <p class="level-2">微吼仅会在以下情况下，公开披露您的个人信息：</p>
        <p class="level-2">
          1、获得您明确同意或者基于您的主动选择，微吼可能会公开披露您的个人信息；
        </p>
        <p class="level-2">
          2、在法律、法律程序、诉讼或者政府主管部门依法要求的情况下，微吼会公开披露您的个人信息。执法机关提出披露要求，微吼会及时通知您，除非执法机关另有禁止通知的要求。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（四）事先征得授权同意的例外</p>
        <p class="level-2">
          出现以下情形之一，微吼共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
        </p>
        <p class="level-2">1、与国家安全、国防安全有关的；</p>
        <p class="level-2">2、与公共安全、公共卫生、重大公共利益有关的；</p>
        <p class="level-2">3、与犯罪侦查、起诉、审判和判决执行有关的；</p>
        <p class="level-2">4、您自行向社会公众公开的个人信息；</p>
        <p class="level-2">
          5、从合法公开披露的信息中收集到个人信息的，如合法的新闻报道、政府信息公开渠道。
        </p>
        <p class="level-2">
          根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的处理将无需另行向您通知并征得您的同意。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">三、微吼如何存储您的个人信息</p>
      <section>
        <p class="level-2 font-bold">（一）存储地点</p>
        <p class="level-2">
          微吼将严格依照法律法规的规定，在中华人民共和国境内运营过程中收集和产生的您的个人信息存储于境内。微吼不会将上述信息传输至境外，但以下情形除外：
        </p>
        <p class="level-2">1、适用的法律有明确规定；</p>
        <p class="level-2">
          2、获得您的明确授权同意，并按照国家网信部门会同国务院有关部门制定的办法进行安全评估。
        </p>
        <p class="level-2">
          3、如确因业务需要向境外传输数据，微吼将通过国家网信部门安全评估，并签署相关数据保护协议，确保符合《个人信息出境标准合同》等要求。
        </p>
      </section>
      <section>
        <p class="level-2 font-bold">（二）存储期限</p>
        <p class="level-2">
          微吼仅会在为您提供产品或者服务所必需的期间内保留您的个人信息。例如：手机号码，当您使用手机号码注册登录后，微吼需要持续保留您的手机号码，以便于向您提供正常的服务、应对您可能的查询和客服投诉并保障您的账号和系统安全。您发布的内容及相关信息，在您未撤回、删除或者未注销账号之前，微吼有权利但无义务予以保留用于向您提供持续的业务功能。
        </p>
        <p class="level-2">
          如果您注销账号、主动删除个人信息或者信息超出必要的保存期限后，微吼有权将对您的个人信息进行删除或者匿名化处理。
        </p>
        <p class="level-2">有下列情形之一的，微吼有可能会更改个人信息的存储时间：</p>
        <p class="level-2">1、为遵守适用的法律法规等有关规定；</p>
        <p class="level-2">2、为遵守法院判决、裁定或者其他法律程序的规定；</p>
        <p class="level-2">3、为遵守相关政府机关或者法定授权组织的要求；</p>
        <p class="level-2">
          4、为执行相关协议或者本政策、维护社会公共利益，为保护您、微吼或者微吼关联公司、其他用户或者雇员的人身财产安全或者其他合法权益所合理必需的用途。
        </p>
        <p class="level-2">
          对于您发布的直播内容及相关信息，您应当自行下载并保存。基于存储空间、成本费用考量等原因，微吼有权随时删除前述内容及相关信息。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">四、微吼如何保护您的个人信息</p>
      <section>
        <p class="level-2">
          截至目前，微吼已经通过了国家信息安全等级保护（三级）的测评和备案，并通过了国际标准化组织的ISO9001、ISO27001、ISO27701、ISO20000认证，其中ISO27001信息安全认证及ISO27701隐私信息管理认证是有关个人信息和隐私保护的重要认证。
        </p>
        <p class="level-2">
          微吼非常重视您的个人信息安全，将努力采取合理的安全措施来保护您的个人信息，确保个人信息处理活动符合法律、行政法规或者依法可适用的其他制度的要求，并防止未经授权的访问以及个人信息泄露、篡改、丢失。
        </p>
        <p class="level-2">
          微吼会使用不低于行业通常水平的加密、去标识化技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
        </p>
        <p class="level-2">
          微吼会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。微吼采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息。微吼会定期对人员进行安全教育和培训，并适时对数据和技术进行安全审计。
        </p>
        <p class="level-2">
          微吼会制定并组织实施个人信息安全事件应急预案，并在发生个人信息安全事件时立即启动应急预案，努力控制这些安全事件的影响，防止不利后果扩大。一旦发生或者可能发生个人信息安全事件（泄露、篡改、丢失）的，微吼将按照法律法规的要求，及时向您告知以下情况：发生或者可能发生泄露、篡改、丢失的个人信息种类、原因和可能造成的危害，微吼已采取的补救措施，您可以采取的减轻危害的措施以及与微吼联系的方式。
        </p>
        <p class="level-2">
          如果微吼的物理、技术、或管理防护设施遭到破坏，不幸发生信息安全事件，微吼将及时将事件相关情况以推送通知、邮件、短信及相关形式告知您，难以逐一告知时，微吼会采取合理、有效的方式发布公告。同时，微吼还将按照相关监管部门要求，上报个人信息安全事件的处置情况。
        </p>
        <p class="level-2">
          尽管已经采取了上述合理有效措施，并已经遵守了相关法律法规要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，微吼将尽力确保您提供给微吼的个人信息的安全性。
        </p>
        <p class="level-2">
          您知悉并理解，您接入微吼的服务所用的系统和通讯网络，有可能因微吼可控范围外的其他因素而出现问题和障碍。因此微吼强烈建议您采取有效的、积极的措施保护您的个人信息安全，包括但不限于使用复杂密码（组合密码）、定期修改密码、不将自己的账号密码及相关个人信息透露给他人等。
        </p>
        <p class="level-2">
          您一旦离开微吼，浏览或者使用第三方提供的其他产品或者服务，微吼将没有能力和直接义务保护您向第三方提交的任何个人信息，无论您登录、浏览或者使用上述产品或者服务是否基于呈现于微吼提供的产品和服务中的链接或者引导。微吼在此提示您，请您务必注意审慎评估第三方产品或者服务，防止您的个人信息或者隐私被非法获取、泄露等。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">五、微吼如何使用 Cookie 和同类技术</p>
      <section>
        <p class="level-2">
          Cookie 和设备信息标识等同类技术是互联网中普遍使用的技术。Cookie
          通常包含标识符、站点名称以及一些号码和字符。为确保网站正常运转、同时为使您获得更轻松的访问体验，当您使用微吼及相关服务时，微吼可能会使用相关技术向您的设备发送一个或者多个Cookie
          或者匿名标识符，以收集、标识您访问、使用微吼产品和服务时的信息。微吼承诺，不会将Cookie
          用于本政策所述目的之外的任何其他用途。微吼使用Cookie
          和同类技术主要为了实现以下功能或者服务：
        </p>
        <p class="level-2">
          1、微吼可能会设置认证与保障安全性的Cookie
          或者匿名标识符，使微吼确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助微吼改进服务效率，提升您登录和响应速度；
        </p>
        <p class="level-2">
          2、使用此类技术可以帮助您省去重复填写个人信息、输入搜索内容的步骤和流程（包括但不限于记录搜索、表单填写等）；
        </p>
        <p class="level-2">
          3、微吼可能会利用此类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务、推荐您感兴趣的信息或者功能，并优化您对广告或者内容的选择；
        </p>
        <p class="level-2">
          4、在微吼的分享页中，微吼可能会使用Cookie对浏览活动进行记录，用于向您推荐信息和排查崩溃、延迟的相关异常情况以及探索更好的服务方式。
        </p>
        <p class="level-2">
          您可根据自己的偏好管理或者删除 Cookie。有关详情，请参见
          AboutCookies.org。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止Cookie
          的功能。但如果您这么做，可能影响部分功能（如无法自动登录），并且需要在每一次访问微吼的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">六、微吼如何处理未成年人的个人信息</p>
      <section>
        <p class="level-2">
          微吼非常重视对未成年人个人信息的保护。根据相关法律法规的规定，如您是未满十八周岁的未成年人，在试用、使用微吼的产品和服务之前，您应当和您的父母或者其他监护人仔细阅读本政策，并在征得您的父母或者其他监护人的同意之后（如签署同意书等），才能并在父母或者其他监护人指导下使用微吼的产品或者服务。
        </p>
        <p class="level-2">
          根据国家相关法律法规的对保护未成年人个人信息的规定，只有在法律允许、父母或者其他监护人明确同意或者保护未成年人所必要的情况下微吼才会收集、使用、共享或者披露未成年人的个人信息。如果发现在未事先获得可证实的父母或者其他监护人同意的情况下收集了未成年人的个人信息，微吼将主动终止账号并设法尽快删除相关信息。如果您认为微吼可能不当地持有未成年人的信息，请您按照本政策所列明的公开联系方式与微吼取得联系。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">七、您的权利及微吼处理个人信息的基本原则</p>
      <section>
        <p class="level-2 font-bold">（一）您的权利</p>
        <p class="level-2">1、访问、修改和删除信息</p>
        <p class="level-2">
          您可以在使用微吼服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息。具体访问个人信息方式：登录账号
          → 点击【个人中心】→ 选择【安全设置】→
          查看/编辑信息。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。如您发现微吼违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求微吼删除。如您发现微吼收集、存储的您的个人信息有错误的，您也可以要求微吼更正。
        </p>
        <p class="level-2">
          您应谨慎进行个人信息和数据的删除、更改等处理操作，并承担因该等操作所产生的全部后果。
        </p>
        <p class="level-2">2、注销账号</p>
        <p class="level-2">
          您可以按照本政策所列明的公开联系方式联系微吼协助您注销账号。在您主动注销账号之后，微吼将停止为您提供与账号相关联的所有产品和服务，并有权根据适用法律法规的要求删除您的个人信息，或进行匿名化处理。您应根据自己的需要自行备份本政策所述相关个人信息和数据，微吼暂未提供备份服务。如果您停止使用微吼的产品和服务或者被微吼终止您向您提供服务，微吼有权从服务器上永久地删除您的信息和数据。在服务停止、终止或取消后，微吼没有义务向您返还任何信息和数据。
        </p>
        <p class="level-2">3、改变您授权同意的范围</p>
        <p class="level-2">
          微吼的部分相关产品和服务可能需要相配的个人信息。您可以通过自助改变手机、平板电脑等智能移动设备的设置给予或者收回授权同意。当您收回相关的授权同意后，微吼将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p class="level-2">4、相应请求的限制</p>
        <p class="level-2">
          在您访问、修改或删除相关信息或账号时，微吼可能会要求您进行身份验证，以保障账号的安全。请您理解，由于技术所限、法律或监管要求，微吼可能无法满足您的所有要求，微吼会在合理的期限内答复您的请求。根据相关法律法规及国家标准，当出现如下情形时微吼可能无法响应您的请求：
        </p>
        <p class="level-2">1、与国家安全、国防安全直接相关的；</p>
        <p class="level-2">2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p class="level-2">3、与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p class="level-2">4、有充分证据表明您存在主观恶意或滥用权利的；</p>
        <p class="level-2">5、响应您的请求将导致其他个人、组织的合法权益受到严重损害的；</p>
        <p class="level-2">6、涉及商业秘密的。</p>
      </section>
      <section>
        <p class="level-2 font-bold">（二）处理个人信息的基本原则</p>
        <p class="level-2">
          为了向您提供服务、保障服务的正常运行、改进和优化微吼的产品和服务，微吼会根据权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明等原则处理您的个人信息。微吼收集和使用您的个人信息类型包括两种：
        </p>
        <p class="level-2">
          1、微吼的产品和服务的核心业务功能所必需的信息，此类信息为产品和服务正常运行的必备信息，您须授权微吼收集和使用。如您拒绝，您将无法正常使用微吼的产品和服务。
        </p>
        <p class="level-2">
          2、非核心业务功能可能需要收集的信息，您可以选择是否授权微吼收集。如您拒绝提供，将导致某些功能无法实现或无法达到微吼产品和服务拟达到的效果，但不影响您对核心业务功能的正常使用。
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">八、本政策如何查阅和本政策的修订</p>
      <section>
        <p class="level-2">
          在您申请试用微吼的产品和服务时，或者在您注册微吼账号时，或者在您登录微吼账号时，《微吼用户服务协议》、《微吼隐私政策》等协议会明确向您展示。
          <span class="font-bold font-italic">
            您应当认真阅读、同意本政策后您才能试用或者进一步使用微吼的产品和服务。
          </span>
        </p>
        <p class="level-2">
          为了给您提供更好的服务，微吼产品和服务将不时更新与变化，同时根据国家有关法律法规的规定，微吼会适时对本政策进行修订。本政策修订后，微吼会发出更新后版本。在您申请使用、注册或者登录微吼账号时，您应当认真阅读并审慎作出是否同意的决策。微吼也可能通过功能更新，帮助您更好地管理您的个人信息，请您留意相关的功能说明。
          <span class="font-bold font-italic">
            您有权拒绝同意修订后的政策，但请您知悉，一旦您拒绝同意修订后的政策，可能导致您不能或不能继续完整使用微吼的相关产品和服务功能，或者无法达到相关产品和服务拟达到的效果。
          </span>
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">九、通知送达及推送</p>
      <section>
        <p class="level-2">
          基于本政策，微吼可以通过网页公告、站内消息、公众号通知、您预留的电子邮件、手机短信、电话、信件传送等方式中的任何一种向您发送、送达相关通知、告知、函件等，该等通知于发送之日视为已送达。
        </p>
        <p class="level-2">
          请您确保您预留的手机号码、电子邮件、收件地址等的准确。如发生变更，请您及时进行修改。
          <span class="font-bold font-italic">
            联系方式变更后未提供新方式或者您预留的联系方式不准确，微吼按照既有联系方式中任何一种向您送达相关通知、告知、函件等，视为有效送达，由此产生的法律后果和损失由您承担。
          </span>
        </p>
        <p class="level-2">
          <span class="font-bold font-italic">
            您同意微吼有权通过您预留的电话号码、电子邮件等给您打电话、发短信、发送电子邮件等向您介绍、推销与微吼相关的产品或者服务，或者与微吼不相关的信息，如第三方活动信息、交易信息、商业广告等。如您对相关推送感到不适，您可以直接拒绝，微吼将中止相关行为。
          </span>
        </p>
      </section>
    </div>
    <div>
      <p class="level-1 font-bold">十、法律适用和争议解决、侵权投诉和举报渠道</p>
      <section>
        <p class="level-2">
          本政策受中国法律管辖并按中国法律解释（为本政策之目的，中国仅指中国大陆地区，不包括中国香港、中国澳门和中国台湾）。本政策的任何条款如与届时有效的中国法律相冲突而无效的，不影响其他部分的效力。
        </p>
        <p class="level-2">
          为保护您的合法权益，如果接到第三方对您的投诉举报，微吼有权向投诉方披露你的相关资料和信息（如您的主体信息或者您对投诉的说明、证据等）。如果您投诉第三人，微吼有权将您的主体信息及投诉资料和信息披露给被投诉方。微吼有权组织调解或者要求各方进行协商（如建立邮件群组、微信群组、企微群组、发起群组电话、语音电话等），协商或者调解不成的，微吼有权依照微吼规则作出裁决。
        </p>
        <p class="level-2">
          <span class="font-bold font-italic">
            因本政策条款及履行过程中发生的任何争议，各方同意友好协商解决。如协商不成，各方同意提交北京市朝阳区人民法院通过诉讼方式解决。
          </span>
        </p>
        <p class="level-2">
          如您对本政策有任何疑问、意见或者建议，尤其是对个人信息保护方面有问题的，可以通过以下方式与微吼联系：
        </p>
        <p class="level-2">1、电话：400-888-9970</p>
        <p class="level-2">2、邮箱：audit@vhall.com</p>
        <p class="level-2">
          3、邮寄地址：北京市朝阳区东亿文化产业园三期B座8层 财法中心法务合规部（收）
        </p>
        <p class="level-2">
          微吼将尽快审核您提出的问题或者建议，并在验证您的用户身份后的十五个工作日内回复。
        </p>
      </section>
    </div>
  </div>
</template>
<script>
  import OldHeader from '@/components/OldHeader';
  export default {
    components: {
      OldHeader
    },
    data() {
      return {
        staticData: [
          // 第二块
          {
            label: 'SDK/APP',
            content: '友盟+SDK'
          },
          {
            label: '第三方名称',
            content: '友盟同欣（北京）科技有限公司'
          },
          {
            label: '获取信息类型',
            content: '唯一设备识别码、设备Mac地址、用户位置信息及IP地址'
          },
          {
            label: '使用目的',
            content: '进行诸如用户新增等数据统计'
          },
          {
            label: '必要性',
            content: '提供统计分析服务'
          },
          {
            label: '处理方式',
            content:
              '便于排查和解决问题，提高app性能和稳定性，从而保障您的使用安全和享受更优质的服务'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://developer.umeng.com/docs/147377/detail/209997" target="_blank">https://developer.umeng.com/docs/147377/detail/209997</a>'
          },
          {
            label: '',
            content: ''
          },
          // 第三块
          {
            label: 'SDK/APP',
            content: '相芯SDK'
          },
          {
            label: '第三方名称',
            content: '杭州相芯科技有限公司'
          },
          {
            label: '获取信息类型',
            content: '图片视频数据、设备型号、APP包名、IP地址'
          },
          {
            label: '使用目的',
            content: '对用户输入人像进行美颜'
          },
          {
            label: '必要性',
            content: '用户需求功能'
          },
          {
            label: '处理方式',
            content: '对图像像素进行修改'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://www.faceunity.com/policy.html" target="_blank">https://www.faceunity.com/policy.html</a>'
          },
          {
            label: '',
            content: ''
          },
          // 第四块
          {
            label: 'SDK/APP',
            content: '腾讯优图SDK'
          },
          {
            label: '第三方名称',
            content: '腾讯科技（深圳）有限公司'
          },
          {
            label: '获取信息类型',
            content: '图片视频数据、程序进程名称'
          },
          {
            label: '使用目的',
            content: '对用户输入图像做背景分割'
          },
          {
            label: '必要性',
            content: '用户需求功能'
          },
          {
            label: '处理方式',
            content: '对图像像素进行修改'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://cloud.tencent.com/document/product/1208/73142" target="_blank">https://cloud.tencent.com/document/product/1208/73142</a>'
          },
          {
            label: '',
            content: ''
          },
          // 第五块
          {
            label: 'SDK/APP',
            content: '腾讯语音识别SDK'
          },
          {
            label: '第三方名称',
            content: '腾讯科技（深圳）有限公司'
          },
          {
            label: '获取信息类型',
            content: '日音频数据'
          },
          {
            label: '使用目的',
            content: '语音转文本'
          },
          {
            label: '必要性',
            content: '用户需求功能'
          },
          {
            label: '处理方式',
            content: '基于语音信息生成内容文字'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://cloud.tencent.com/document/product/1093/73072" target="_blank">https://cloud.tencent.com/document/product/1093/73072</a>'
          },
          {
            label: '',
            content: ''
          },
          // 第六块
          {
            label: 'SDK/APP',
            content: 'AZURE语音服务SDK'
          },
          {
            label: '第三方名称',
            content: '微软(中国)有限公司'
          },
          {
            label: '获取信息类型',
            content: '音频数据'
          },
          {
            label: '使用目的',
            content: '语音转文本'
          },
          {
            label: '必要性',
            content: '用户需求功能'
          },
          {
            label: '处理方式',
            content: '基于语音信息生成内容文字'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://privacy.microsoft.com/zh-CN/privacystatement" target="_blank">https://privacy.microsoft.com/zh-CN/privacystatement</a>'
          },
          // 第七块
          {
            label: 'SDK/APP',
            content: '博瑞ONE SDK'
          },
          {
            label: '第三方名称',
            content: '北京博睿宏远数据科技股份有限公司'
          },
          {
            label: '获取信息类型',
            content:
              '日志信息（软件版本、用户标识、用户触发的事件、系统错误信息、时间戳、应用标识符、应用程序版本、应用分发渠道、开放性独立设备标识符（Open UDID）、iOS广告标识符（IDFA)、网卡（MAC）地址、国际移动设备识别码（IMEI）、设备型号、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态）'
          },
          {
            label: '使用目的',
            content: '捕捉页面性能信息、异常信息方便异常排查'
          },
          {
            label: '必要性',
            content:
              '便于排查和解决问题，提高WEB性能和稳定性，从而保障您的使用安全和享受更优质的服务'
          },
          {
            label: '处理方式',
            content: '去标识化、加密传输等安全处理方式'
          },
          {
            label: '第三方隐私政策',
            content:
              '<a href="https://one.bonree.com/open/document/370" target="_blank">https://one.bonree.com/open/document/370</a>'
          }
        ],
        tableCss: 'width: calc(100% - 48px);'
      };
    },
    created() {
      this.judgeIsWap();
      window.addEventListener('resize', () => {
        this.judgeIsWap();
      });
    },
    methods: {
      judgeIsWap() {
        const uA = navigator.userAgent.toLowerCase();
        const ipad = uA.match(/ipad/i) == 'ipad';
        const iphone = uA.match(/iphone os/i) == 'iphone os';
        const midp = uA.match(/midp/i) == 'midp';
        const uc7 = uA.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
        const uc = uA.match(/ucweb/i) == 'ucweb';
        const android = uA.match(/android/i) == 'android';
        const windowsce = uA.match(/windows ce/i) == 'windows ce';
        const windowsmd = uA.match(/windows mobile/i) == 'windows mobile';
        const dom = document.getElementById('app');
        if (!(ipad || iphone || midp || uc7 || uc || android || windowsce || windowsmd)) {
          // PC 端
          dom.style.minWidth = 'unset';
          dom.style.width = '100%';
          dom.style.overflowX = 'hidden';
          dom.style.backgroundColor = '#F5F5F5';
          dom.className = 'page_pc';
        } else {
          // 移动端
          dom.style.minWidth = 'unset';
          dom.style.width = '100%';
          dom.style.overflowX = 'hidden';
          dom.style.backgroundColor = '#F5F5F5';
          dom.className = 'page_mobile';
        }
      },
      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        if (row.label === '') {
          if (columnIndex === 0) {
            return [1, 2];
          } else if (columnIndex === 1) {
            return [0, 0];
          }
        }
      }
    }
  };
</script>
<style lang="less" scoped>
  .privacy-policy {
    padding: 0 0;
    a {
      color: #3562fa;
      &:link {
        color: #3562fa;
      } //设置未访问时的超链接样式
      &:visited {
        color: #3562fa;
      } //设置已访问的链接的样式
      &:hover {
        color: #3562fa;
      } //设置鼠标移动到链接上的样式
      &:active {
        color: #3562fa;
      } //设置链接被激活（鼠标点击与释放之间）时的样式
    }
    > div {
      margin-bottom: 24px;
      &.one {
        margin-top: 24px;
      }
    }
    h1.p-title {
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #dcdcdc;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      color: #262626;
      margin-bottom: 24px;
      padding-top: 40px;
    }
    p {
      text-align: left;
      word-break: break-all;
    }
    p.p-timer {
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: left;
      color: #262626;
      &.margin-24 {
        margin-bottom: 28px;
      }
    }
    p.level-1 {
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #262626;
    }
    p.level-2 {
      text-indent: 32px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #262626;
      &:last-child {
        margin-bottom: 0;
      }
    }
    section {
      margin-bottom: 12px;
    }
    .table-title {
      margin-top: 32px;
    }
    ::v-deep .vh-table {
      margin: 20px auto;
      tr > td {
        padding: 5px 0;
        &:first-child {
          background: #fafafa;
        }
        &:last-child {
          background: #ffffff;
        }
      }
      .cell {
        padding-left: 0;
        padding-right: 0;
      }
    }
    ::v-deep .vh-table--border,
    ::v-deep .vh-table--group {
      border: 1px solid #f0f0f0;
    }
    ::v-deep .vh-table--border td,
    .vh-table--border th,
    .vh-table__body-wrapper .vh-table--border.is-scrolling-left ~ .vh-table__fixed {
      border-right: 1px solid #f0f0f0;
    }
    ::v-deep .vh-table td,
    .vh-table th.is-leaf {
      border-bottom: 1px solid #f0f0f0;
    }
    ::v-deep .vh-table--border::after,
    .vh-table--group::after,
    .vh-table::before {
      background-color: #f0f0f0;
    }
    .font-italic {
      font-style: italic !important;
    }
    .font-bold {
      font-weight: bold !important;
    }
  }
  .page_pc {
    p,
    h1,
    table {
      margin-left: 83px;
      margin-right: 83px;
    }
    .privacy-policy {
      ::v-deep .vh-table {
        width: calc(100% - 166px) !important;
        tr > td {
          padding: 13px 24px;
        }
      }
    }
  }
  .page_mobile {
    p,
    h1,
    table {
      margin-left: 24px;
      margin-right: 24px;
    }
    .privacy-policy {
      ::v-deep .vh-table {
        width: calc(100% - 48px) !important;
        tr > td {
          padding: 13px 24px;
        }
      }
    }
  }
</style>
